import Link from 'next/link';
import React from 'react';
import { AiOutlineExperiment } from 'react-icons/ai';

import { Flex, Text } from '@chakra-ui/react';

export function RekaLink({ href, onClick, minimize }: { href: string; onClick?: () => void; minimize?: boolean }) {
    return (
        <Link href={href} onClick={onClick}>
            <Flex display={'inline-flex'} gap={'8px'} alignItems={'center'} fontSize={'18px'}>
                <AiOutlineExperiment role="presentation" />{' '}
                <Text
                    fontWeight={600}
                    fontFamily={'monospace'}
                    borderBottom={'1px solid white'}
                    textTransform={'uppercase'}
                >
                    Reka
                </Text>{' '}
                {!minimize && <span>Chat</span>}
            </Flex>
        </Link>
    );
}
