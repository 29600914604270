import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { AuthLayoutNew, LandingPage } from '@/components/auth-layout';
import { Button, Text, VStack } from '@chakra-ui/react';

const RESUME_STATE_KEY = 'email-verification-resume';
export function LoginScreen() {
    const router = useRouter();
    const errorMsg = router.query.error_description;
    useEffect(() => {
        // Just a safeguard to prevent this value from living on
        localStorage.removeItem(RESUME_STATE_KEY);
    }, []);
    return (
        <AuthLayoutNew pageTitle={'Login | Reka Chat'}>
            <LandingPage>
                <VStack width={'100%'} gap={'12px'} alignItems={'flex-start'}>
                    <Button size={'lg'} variant={'darkPurple'} as={'a'} href={'/bff/auth/signup'} rel="nofollow">
                        Sign up
                    </Button>
                    <Button variant={'purpleOutline'} size={'lg'} as={'a'} href={'/bff/auth/login'} rel="nofollow">
                        Log in
                    </Button>
                    {errorMsg && <Text color={'red.400'}>{errorMsg}</Text>}
                </VStack>
            </LandingPage>
        </AuthLayoutNew>
    );
}
